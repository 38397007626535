<template>
  <div>
    <section>
      <b-modal
        :active="isComponentModalActive"
        @close="closeModal"
        has-modal-card
      >
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="notificationDetail" class="modal-card-title">
              Detalhes da Notificação
            </p>
          </header>
          <section class="modal-card-body">
            <b-field label="Mensagem">
              <b-message type="is-info">
                {{ message }}
              </b-message>
            </b-field>
            <hr />
            <b-table
              :data="detailsNotification"
              :bordered="true"
              :striped="true"
              :narrowed="false"
              :hoverable="false"
              :loading="false"
              :focusable="false"
              :mobile-cards="true"
              :paginated="true"
              :per-page="5"
              scrollable
            >
              <b-table-column
                field="title"
                label="#"
                v-slot="detailsNotification"
              >
                <strong>{{ detailsNotification.row.id }}</strong>
              </b-table-column>

              <b-table-column
                field="contact_name"
                width="200"
                label="Nome Cliente"
                v-slot="detailsNotification"
              >
                {{
                  detailsNotification.row.contact_name
                    ? detailsNotification.row.contact_name
                    : detailsNotification.row.client_name
                }}
              </b-table-column>

              <b-table-column
                field="client_phone"
                label="Telefone"
                v-slot="detailsNotification"
              >
                {{ detailsNotification.row.client_phone }}
              </b-table-column>

              <b-table-column
                field="attendant_name"
                width="200"
                label="Atendente"
                v-slot="detailsNotification"
              >
                {{
                  detailsNotification.row.attendant_name
                    ? detailsNotification.row.attendant_name
                    : "---"
                }}
              </b-table-column>

              <b-table-column
                field="created_at"
                width="300"
                label="Criado em"
                v-slot="detailsNotification"
              >
                {{
                  detailsNotification.row.created_at
                    ? formatDateTime(detailsNotification.row.created_at)
                    : ""
                }}
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="detailsNotification"
              >
                {{ !!detailsNotification.row.is_open ? "Aberto" : "Fechado" }}
              </b-table-column>

              <b-table-column
                field="actions"
                label="Mensagens"
                v-slot="detailsNotification"
              >
                <MessagesTicketButton :ticket="detailsNotification.row"/>
              </b-table-column>

              <b-table-column
                field="transferTicket"
                width="200"
                label="Transferir"
                v-slot="detailsNotification"
              >
                <TransferTicketButton
                  :ticket="detailsNotification.row"
                  v-on:reloadTickets="info('reloadTickets')"
                />
              </b-table-column>

              <b-table-column
                field="closeTicket"
                width="200"
                label="Fechar"
                v-slot="detailsNotification"
              >
                <CloseTicketButton
                  :ticket="detailsNotification.row"
                  v-on:reloadTickets="info('reloadTickets')"
                />
              </b-table-column>

              <template #footer>
                <div class="has-text-left">
                  Quantidade de registros {{ detailsNotification.length }}
                </div>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import mixin from '../../utils/mixins'
import { get } from '../../utils/api'
import CloseTicketButton from '@/components/tickets/CloseTicketButton'
import TransferTicketButton from '@/components/tickets/TransferTicketButton'
import MessagesTicketButton from '@/components/tickets/MessagesTicketButton'
import { mapActions } from 'vuex'

export default {
  name: 'ModalNotificationDetail',
  components: {
    CloseTicketButton,
    TransferTicketButton,
    MessagesTicketButton
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    notificationDetail: {
      type: Object,
      required: false
    }
  },
  mixins: [mixin],
  data () {
    return {
      detailsNotification: [],
      isActive: true,
      message: '',
      selectedTicket: null
    }
  },
  created () {
    if (this.notificationDetail) {
      this.getNotificationDetail(this.notificationDetail)
      this.markAsReadNotification(this.notificationDetail)
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
      this.fetchUnreadNotifications()
    },
    async getNotificationDetail (notification) {
      const response = await get(`notifications/${notification.id}/tickets`)
      this.detailsNotification = response.data
      this.message = notification.message
    },
    async markAsReadNotification (notification) {
      this.markNotificationAsRead(notification)
    },
    ...mapActions(['fetchTicketsReport', 'fetchAttendants', 'fetchUnreadNotifications', 'markNotificationAsRead']),
    applyTicketsFilter () {
      this.fetchTicketsReport({
        client_name_phone: this.namePhoneFilter,
        ticket_status: this.statusFilter,
        attendants_filter: this.attendantsFilter,
        contacts_filter: this.contactsFilter,
        departments_filter: this.departmentsFilter,
        type_filter: this.typeFilter,
        date_from_filter: this.dateFromFilter,
        date_to_filter: this.dateToFilter,
        ticket_id: this.ticketID,
        tickets_without_department: this.withoutDepartmentFilter
      })
    },
    async info (action, ticket) {
      switch (action) {
        case 'transfer':
          this.selectedTicket = ticket
          await this.fetchAttendants()
          break
        case 'reloadTickets':
          this.getNotificationDetail(this.notificationDetail)
          this.applyTicketsFilter()
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
  overflow-y: auto !important;
}
</style>
