<template>
  <div>
    <div v-if="!!notifications && notifications.notifications.length > 0">
      <ModalNotificationDetail
        v-if="isComponentModalActive"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive"
        :notificationDetail="notificationDetail"
      />

      <b-field grouped group-multiline>
          <b-select v-model="perPage" @input="handleItensPerPage" placeholder="Items por página">
              <option v-for="opt in options" v-bind:key="opt.id" :value="opt.value">{{ opt.name }}</option>
          </b-select>
      </b-field>

      <b-table
        v-if="notifications"
        :data="notifications.notifications"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
        paginated
        backend-pagination
        :total="notifications.total"
        @page-change="onPageChange"
        :per-page="filter.per_page"
        scrollable
      >
        <b-table-column sortable field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="title" width="400" label="Título" v-slot="props">
          <b-tag v-if="!props.row.seen" class="is-info is-light">Novo</b-tag> {{ props.row.title }}
        </b-table-column>

        <b-table-column
          field="message"
          label="Mensagem"
          v-slot="props"
        >
          {{props.row.message}}
        </b-table-column>

        <b-table-column
          field="createdAt"
          label="Criado em"
          v-slot="props"
        >
          {{ props.row.created_at ? formatDateTime(props.row.created_at) : "" }}
        </b-table-column>

        <b-table-column field="details" label="Detalhes" v-slot="props">
          <b-button
            class="button is-info is-outlined"
            type="button"
            @click="OpenNotificationModal(props.row)"
          >
            Detalhes
          </b-button>
        </b-table-column>

        <template #footer>
          <div class="has-text-left">
            Quantidade de registros {{ notifications !== null ? notifications.total : getnotifications.total }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
import ModalNotificationDetail from '@/components/modals/ModalNotificationDetail.vue'
import { mapActions } from 'vuex'
import { get } from '../../utils/api'

export default {
  name: 'NotificationsList',
  components: {
    ModalNotificationDetail
  },
  props: {
    // notifications: {
    //   type: Object,
    //   required: true
    // }
  },
  mixins: [mixin],
  data () {
    return {
      isComponentModalActive: false,
      notificationDetail: null,
      notifications: null,
      perPage: null,
      filter: {
        page: 1,
        per_page: 20
      },
      options: [
        { id: 4, name: '20 items por página', value: 20 },
        { id: 1, name: '5 items por página', value: 5 },
        { id: 2, name: '10 items por página', value: 10 },
        { id: 3, name: '15 items por página', value: 15 }
      ]
    }
  },
  created () {
    this.loadAsyncData()
  },
  methods: {
    ...mapActions(['fetchNotifications']),
    handleOpenNotificationDetailClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.notificationDetail = null
      this.isComponentModalActive = false
    },
    OpenNotificationModal (notification) {
      this.notificationDetail = notification
      this.handleOpenNotificationDetailClick()
    },
    async loadAsyncData () {
      const response = await get(`notifications?page=${this.filter.page}&per_page=${this.filter.per_page}`)
      this.notifications = response.data
      this.perPage = this.filter.per_page
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    handleItensPerPage () {
      this.filter.per_page = this.perPage
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
