<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Notificações
    </hero-bar>
    <section class="section is-main-section">
      <b-loading v-if="fetchingNotifications" :is-full-page="true" v-model="fetchingNotifications" :can-cancel="false"></b-loading>
      <NotificationsList v-else/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import NotificationsList from '@/components/notifications/NotificationsList.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotificationsScreen',
  components: {
    HeroBar,
    TitleBar,
    NotificationsList
  },
  data () {
    return {
      filter: {
        page: 1,
        per_page: 20
      }
    }
  },
  computed: {
    ...mapGetters(['fetchingNotifications']),
    titleStack () {
      return ['Dashboard', 'Notificações']
    }
  },
  methods: {
    ...mapActions(['fetchNotifications', 'openNotification'])
  },
  created () {
    // this.fetchNotifications(this.filter)
  }
}
</script>
